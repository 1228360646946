<template>
	<section class="sect-description">
		<v-container class="pt-16 pb-16 text-center font-weight-thin">
			<v-row>
				<v-col md="10" offset-md="1">
					<p class="text-h5 mb-5">МАРС Отель — идеальное место для комфортного проживания в Барнауле. Мы предлагаем современные гостиничные номера и широкий выбор современных апартаментов, доступных для посуточной аренды. Наши квартиры идеально подходят как для семейных, так и для деловых поездок. 
						Каждое помещение обставлено с заботой о наших гостях и оборудовано всем необходимым для комфортного отдыха. Мы также предоставляем все виды отчетных документов, чтобы ваше пребывание было максимально удобным.
						Откройте для себя уют и стиль в МАРС Отель, где каждый гость чувствует себя как дома. Бронируйте прямо сейчас и наслаждайтесь непревзойденным сервисом!
					</p>
				</v-col>
			</v-row>
		</v-container>
	</section>

</template>

<style lang="sass" scoped>
.sect-description
	background-image: url(../../assets/bg.png)
	background-repeat: no-repeat
	background-size: 100% 100%
</style>
